import '../styles/index.scss';
import $ from "jquery";
import 'selectric';
import 'magnific-popup';
import Inputmask from "inputmask";

/*
   HELPERS
 */
function validEmail(email) {
    var pattern = /^([\w-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([\w-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$/;
    return $.trim(email).match(pattern) ? true : false;
}
function isValidZipCode(zip) {
    return /^\d{5}(-\d{4})?$/.test(zip);
}

var get_params = function(search_string) {
    var parse = function(params, pairs) {
        var pair = pairs[0];
        var parts = pair.split('=');
        var key = decodeURIComponent(parts[0]);
        var value = decodeURIComponent(parts.slice(1).join('='));

        // Handle multiple parameters of the same name
        if (typeof params[key] === "undefined") {
            params[key] = value;
        } else {
            params[key] = [].concat(params[key], value);
        }

        return pairs.length == 1 ? params : parse(params, pairs.slice(1));
    };

    // Get rid of leading ?
    return search_string.length == 0 ? {} : parse({}, search_string.substr(1).split('&'));
};
/*
 USER IP
 */
$.ajax({
    url : '//www.reallygreatrate.com/api/user/ip_address/',
    dataType : 'json',
    method: 'get',
    data: {},
    success: function (response) {
        $('#user_ip').val(response['ip_address']);
    }
});
/*
   MAGNIFIC POPUP
 */
$('.mfp-iframe').each(function() { // the containers for all your galleries
    $(this).magnificPopup({
        type: 'iframe',
        mainClass: 'iframe-popup'
    });
});

$('.mfp-inline').each(function() { // the containers for all your galleries
    $(this).magnificPopup({
        type: 'inline'
    });
});

/*
    POPUP
*/
function showPopUp() {
    setTimeout(function () {
        $('.step-form .step.active .radio-button a').first().focus();
    },301);
    $('html').addClass('popup-open');
    $('.popup').fadeIn();
    $(window).scrollTop(0);
}
function hidePopUp() {
    $('html').removeClass('popup-open');
    $('.popup').fadeOut();
}
/*
    Generate a TrustedForm Certificate
 */
$('input').on('input', function() {
    this.setAttribute("value", $(this).val());
});

var field = 'xxTrustedFormCertUrl';
var provideReferrer = false;
var tf = document.createElement('script');
tf.type = 'text/javascript'; tf.async = true;
tf.src = 'http' + ('https:' == document.location.protocol ? 's' : '') +
    '://api.trustedform.com/trustedform.js?provide_referrer=' + escape(provideReferrer) + '&field=' + escape(field) + '&l='+new Date().getTime()+Math.random();
var s = document.getElementsByTagName('script')[0];
s.parentNode.insertBefore(tf, s);
/*
    LEAD ID
 */
var s = document.createElement('script');
s.id = 'LeadiDscript_campaign';
s.type = 'text/javascript';
s.async = true;
s.src = (document.location.protocol + '//d1tprjo2w7krrh.cloudfront.net/campaign/9cf731ba-6139-dbff-114f-76f196e05eb4.js');
var LeadiDscript = document.getElementById('LeadiDscript');
LeadiDscript.parentNode.insertBefore(s, LeadiDscript);
/*
    GET Default params
 */
var params = get_params(location.search);
// publisher_id
if(typeof params.publisher_id !== "undefined") {
    $('input[name="publisher_id"]').val(params.publisher_id);
} else {
    $('input[name="publisher_id"]').val(632);
}
// subid
if(typeof params.subid !== "undefined") {
    $('input[name="subid"]').val(params.subid);
} else {
    $('input[name="subid"]').val('');
}
// rcid
if(typeof params.rcid !== "undefined") {
    $('input[name="rcid"]').val(params.rcid);
} else {
    $('input[name="rcid"]').val('');
}
/*
    HID
 */
$.ajax({
    url : '//www.reallygreatrate.com/api/hdi/?upload_type=' + $('input[name="upload_type"]').val() + '&publisher_id=' + $('input[name="publisher_id"]').val(),
    dataType : 'json',
    method: 'get',
    data: {},
    success: function (response) {
        $('input[name="hid"]').val(response['hid']);
    }
});
/*
    ZIP CODE
 */
var zip_response = null;
$('.start-form button').click(function (e) {
    e.preventDefault();
    var $el = $(this).prev('input');
    var $button = $(this);
    var value = $el.val();
    var $form = $el.parent('form');
    $form.find('.error').fadeOut();
    $el.removeClass('input-error');

    if(!$button.is(":disabled")) {
        $button.prop('disabled', true);
        if(value === "" || !isValidZipCode(value)) {
            $el.addClass('input-error');
            $form.find('.error').fadeIn();
        } else {
            // Check zip code
            $.ajax({
                url: '//www.reallygreatrate.com/api/zipcode_lookup/?zip=' + value,
                dataType: 'json',
                method: 'get',
                data: {'zip': value},
                success: function (response) {
                    zip_response = response;
                    zip_response['zip'] = value;

                    $('input[name="zip"]').val(value);
                    $('input[name="city"]').val(response['city']);
                    $('input[name="state"]').val(response['state']);

                    $('.street-info').text(response['city']+', '+response['state']+', '+value);

                    if (response['city']) {
                        getProvider(response['state']);
                        showPopUp();
                    } else {
                        $el.addClass('input-error');
                        $form.find('.error').fadeIn();
                    }
                }
            });
        }
        $button.prop('disabled', false);
    }
});

$(document).on('change', '.radio-list:visible input', function () {
    $('.radio-list:visible li').removeClass('active');
    $(this).parent('label').parent('li').addClass('active');
});

var step_2 = {
    validate : function () {
        var $button = $('.step-form .step.active button');
        if(!$button.is(":disabled")) {
            $('.step-form .step.active .errors').empty();
            $button.prop('disabled', true);
            $('.step-form .step.active').removeClass('has-errors');
            if (!$('.step-form .step.active input[name="field_4"]:checked').val()) {
                $('.step-form .step.active .errors').html('<span class="error">Please Select Current Monthly Power Bill</span>');
                $('.step-form .step.active').addClass('has-errors');
            }
            $button.prop('disabled', false);
        }
    }
};


var step_3 = {
    select : function () {
        // Init custom selects
        $('#provider').selectric({
            nativeOnMobile: true,
            onChange: function (element) {
                $('.select-wrap').addClass('changed');
                var el_val = $(element).find('option:selected').val();
                var el_text = $(element).find('option:selected').text();
                //if(el_text !== 'Other') {
                    $('input[name="provider-radio"]').prop('checked', false);
                    $('input[name="field_2"]').val(el_val);
                    //$('input[name="field_2"]').next('span').text(el_text);
                //}
            }
        });
        $(document).on('change', '#provider', function () {
            var val = $('#provider option:selected').val();
            $('input[name="provider-radio"]').prop('checked', false);
            $('input[name="field_2"]').val(val);
        });
    },
    validate : function () {
        var $button = $('.step-form .step.active button');

        if(!$button.is(":disabled")) {
            $button.prop('disabled', true);
            $('.step-form .step.active .errors').empty();
            $('.step-form .step.active').removeClass('has-errors');
            if ($('input[name="field_2"]').val() === "") {
                $('.step-form .step.active .errors').html('<span class="error">Please Select Utility Provider</span>');
                $('.step-form .step.active').addClass('has-errors');
            }
            $button.prop('disabled', false);
        }
    }
};

step_3.select();

var step_4 = {
    validate : function () {
        var $button = $('.step-form .step.active button');
        if(!$button.is(":disabled")) {
            $('.step-form .step.active .errors').empty();
            $button.prop('disabled', true);
            $('.step-form .step.active').removeClass('has-errors');
            if (!$('.step-form .step.active input[name="field_1"]:checked').val()) {
                $('.step-form .step.active .errors').html('<span class="error">Please Select how much sun does your roof get</span>');
                $('.step-form .step.active').addClass('has-errors');
            }
            $button.prop('disabled', false);
        }
    }
};

var step_5 = {
    validate : function () {
        var $button = $('.step-form .step.active button');
        if(!$button.is(":disabled")) {
            $('.step-form .step.active .errors').empty();
            $button.prop('disabled', true);
            $('.step-form .step.active').removeClass('has-errors');
            // First name
            var first_name = $('.step-form .step.active input[name="first_name"]').val();
            first_name = $.trim(first_name);
            // Last name
            var last_name = $('.step-form .step.active input[name="last_name"]').val();
            last_name = $.trim(last_name);

            if (first_name === "" && last_name === "") {
                $('.step-form .step.active .errors').append('<span class="error">Please fill in your First Name and Last Name</span>');
                $('.step-form .step.active').addClass('has-errors');
            } else {
                if (first_name === "") {
                    $('.step-form .step.active .errors').append('<span class="error">Please fill in your First Name</span>');
                    $('.step-form .step.active').addClass('has-errors');
                }
                if (last_name === "") {
                    $('.step-form .step.active .errors').append('<span class="error">Please fill in your Last Name</span>');
                    $('.step-form .step.active').addClass('has-errors');
                }
            }
            $button.prop('disabled', false);
        }
    }
};
var step_6 = {
    validate : function () {
        var $button = $('.step-form .step.active button');
        if(!$button.is(":disabled")) {
            $('.step-form .step.active .errors').empty();
            $button.prop('disabled', true);
            $('.step-form .step.active').removeClass('has-errors');
            // First name
            var email = $('.step-form .step.active input[name="email_address"]').val();
            email = $.trim(email);
            // Last name
            var phone = $('.step-form .step.active input[name="home_phone"]').val();
            phone = $.trim(phone);
            phone = phone.replace(/[^0-9]/g, '');

            if (email === "" && phone === "") {
                $('.step-form .step.active .errors').append('<span class="error">Please fill in your Email Address and Phone Number</span>');
                $('.step-form .step.active').addClass('has-errors');
            } else {
                if (email === "") {
                    $('.step-form .step.active .errors').append('<span class="error">Please fill in your Email Address</span>');
                    $('.step-form .step.active').addClass('has-errors');
                } else {
                    if(!validEmail(email)) {
                        $('.step-form .step.active .errors').append('<span class="error">Please check your Email Address formatting (email@domain.com)</span>');
                        $('.step-form .step.active').addClass('has-errors');
                    }
                }
                if (phone === "") {
                    $('.step-form .step.active .errors').append('<span class="error">Please fill in your Phone Number</span>');
                    $('.step-form .step.active').addClass('has-errors');
                } else {
                    if(phone.length < 10) {
                        $('.step-form .step.active .errors').append('<span class="error">Please check Phone Number formatting (9999999999)</span>');
                        $('.step-form .step.active').addClass('has-errors');
                    }
                }
            }
            $button.prop('disabled', false);
        }
    }
};

var step_7 = {
    validate : function () {
        var $button = $('.step-form .step.active button');
        if(!$button.is(":disabled")) {
            $('.step-form .step.active .errors').empty();
            $button.prop('disabled', true);
            $('.step-form .step.active').removeClass('has-errors');
            // Adress
            var street = $('.step-form .step.active input[name="street_address"]').val();
            street = $.trim(street);

            if (street === "") {
                $('.step-form .step.active .errors').append('<span class="error">Please fill in your Street Address</span>');
                $('.step-form .step.active').addClass('has-errors');
            }
            $button.prop('disabled', false);
        }
    }
};

function changeStep(step) {

    $('.step-form .step').removeClass('active');
    $('.step-form .step[data-step="'+step+'"]').addClass('active');
    $('.form-top .steps .current').text(step);
    //$('.step-form .step.active input').first().focus();
    switch(step) {
        case 3:
            //$('input[name="field_2"]').val($('input[name="provider-radio"]:checked').val());
            break;
        case 6:
            // Add input mask
            var selector = document.getElementById("home_phone");
            var im = new Inputmask("(999) 999-9999");
            im.mask(selector);
            break;
    }
}

function getProvider(state) {
    $.ajax({
        url : '//www.reallygreatrate.com/api/tools/electricity_providers/?state=' + state,
        dataType : 'json',
        method: 'get',
        data: {'state' : state},
        success: function (response) {
            // First option blank
            $('#provider').html('<option value="" class="empty">- Select Utility Provider -</option>');

            $.each(response, function(key, value) {
                // Append to original select
                $('#provider').append('<option value="'+value['value']+'">' + value['text'] + '</option>');
            });
            // Refresh Selectric
            $('#provider').selectric('refresh');
        }
    });
}

$(document).on('click', '.step-back', function (e) {
    e.preventDefault();
    var step = $('.step-form .step.active').data('step') - 1;
    if(step > 1) {
        changeStep(step);
    } else {
        // hide popup
        hidePopUp();
    }
});

$(document).on('submit', 'form[name="leadform"]', function (e) {
    e.preventDefault();
});

$(document).on('click', '.step-button:visible', function (e) {
    e.preventDefault();
    var step = $(this).data('step');
    if(step === 'submit') {
        step_7.validate();
        if(!$('.step-form .step.active').hasClass('has-errors')) {
            //submit
            // Ajax call to post data
            $.ajax({
                url: 'http://www.reallygreatrate.com/api/solar/',
                dataType: 'json',
                method: 'post',
                data: {
                    first_name 			: $('input[name="first_name"]').val(),
                    last_name 			: $('input[name="last_name"]').val(),
                    email_address 		: $('input[name="email_address"]').val(),
                    city 				: $('input[name="city"]').val(),
                    street_address  	: $('input[name="street_address"]').val(),
                    state 				: $('input[name="state"]').val(),
                    zip 				: $('input[name="zip"]').val(),
                    upload_type 		: $('input[name="upload_type"]').val(),
                    server_code 		: $('input[name="server_code"]').val(),
                    home_owner  		: $('input[name="own_home"]').val(),
                    // "field_12" <- Credit Status
                    field_12 			: $('input[name="field_12"]').val(),
                    // "field_14" <- House Type
                    field_14 			: $('input[name="field_14"]').val(),
                    field_2 			: $('input[name="field_2"]').val(),
                    field_4 			: $('input[name="field_4"]').val(),
                    field_1 			: $('input[name="field_1"]').val(),
                    ipaddress 			: $('input[name="ipaddress"]').val(),
                    hid 				: $('input[name="hid"]').val(),
                    publisher_id 		: $('input[name="publisher_id"]').val(),
                    xxTrustedFormToken  : $('#TrustedFormToken').val(),
                    field_13 			: $('input[name="field_13"]').val(),
                    home_phone 			: $('input[name="home_phone"]').val(),
                    subid 				: $('input[name="subid"]').val(),
                    rcid 				: $('input[name="rcid"]').val()
                },
                success: function (results) {
                    var iframe_html = '';
                    if(typeof results.pixel["pixel"] !== "undefined") {
                        iframe_html = results.pixel["pixel"];
                        $('body').append(iframe_html);
                    }
                    $('.step.active').removeClass('active');
                    $('.step.step-conformation').addClass('active');
                    $('.form-top .steps').empty();
                }
            });

        }
    } else {

        switch(step - 1) {
            case 2:
                step_2.validate();
                break;
            case 3:
                step_3.validate();
                break;
            case 4:
                step_4.validate();
                break;
            case 5:
                step_5.validate();
                break;
            case 6:
                step_6.validate();
                break;
        }
        if(!$('.step-form .step.active').hasClass('has-errors')) {
            changeStep(step);
        }
    }
});

$('html').addClass('ready');

// Opt out form
$(document).on('click', '#opt-out .opt-out-button', function(e) {
    e.preventDefault();
    var email1 = $.trim($('#emailaddr').val());
    var email2 = $.trim($('#emailaddr2').val());
    var $errors = $('#opt-out .errors');
    var $button = $('#opt-out .opt-out-button');
    $errors.empty();
    if(!$button.is(":disabled")) {
        $button.prop('disabled', true);
        if(email1.length === 0 && email2.length === 0) {
            $errors.append('<span class="error">Must fill Email and Confirm Email fields</span>');
        } else {
            if(email1.length === 0) {
                $errors.append('<span class="error">Must fill Email field</span>');
            } else if(email2.length === 0) {
                $errors.append('<span class="error">Must fill Confirm Email field</span>');
            } else {
                if(!validEmail(email1) && !validEmail(email2)) {
                    $errors.append('<span class="error">Please enter valid Email and Confirm Email</span>');
                } else {
                    if(!validEmail(email1)) {
                        $errors.append('<span class="error">Please enter valid Email</span>');
                    } else if(!validEmail(email2)) {
                        $errors.append('<span class="error">Please enter valid Confirm Email</span>');
                    } else {
                        $.ajax({
                            url : '//www.reallygreatrate.com/api/unsub/?emailaddr=' + email1 + '&table=solar',
                            dataType : 'json',
                            method: 'get',
                            data: {},
                            success: function (response) {
                                if(response['status'] == 'success') {
                                    $('.opt-out-form .form-content').html('Thank you! Your request will be processed in 24-48 hours.');
                                }
                            }
                        });
                    }
                }
            }
        }
        $button.prop('disabled', false);
    }
});